import { useEffect } from "react";  

const UseFacebookPixel = (pixelId) => {  
  useEffect(() => {  
    // Check if fbq is already initialized to avoid duplication  
    if (!window.fbq) {  
      (function (f, b, e, v, n, t, s) {  
        if (f.fbq) return;  
        n = f.fbq = function () {  
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);  
        };  
        if (!f._fbq) f._fbq = n;  
        n.push = n;  
        n.loaded = true;  
        n.version = '2.0';  
        n.queue = [];  
        t = b.createElement(e);  
        t.async = true;  
        t.src = v;  
        s = b.getElementsByTagName(e)[0];  
        s.parentNode.insertBefore(t, s);  
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');  
      
      // Initialize Pixel with the given ID  
      window.fbq('init', pixelId);  
      window.fbq('track', 'PageView');  
    }  
  }, [pixelId]);  
};  

export default UseFacebookPixel;